<template>
  <div
    :class="
      !content.config.showSliderOnDesktop?.value &&
      'md:grid md:grid-cols-2 md:gap-1'
    "
    class="flex flex-nowrap bg-white relative"
  >
    <ContentSlider
      :slides="content.data as Schemas['Product'][]"
      attach-buttons-to-query-selector="a > img"
      :breakpoints="content.config.breakpoints?.value"
      :class="!content.config.showSliderOnDesktop?.value && 'md:hidden'"
    >
      <template #slide="{ slide }">
        <ProductCard
          :product="slide"
          class="h-full"
          :size="
            breakpoints['md']
              ? productCardSizes['REGULAR']
              : productCardSizes['LARGE']
          "
        />
      </template>
    </ContentSlider>
    <template v-if="!content.config.showSliderOnDesktop?.value">
      <div
        v-for="product in content.data as Schemas['Product'][]"
        :key="product.id"
        class="hidden md:inline-block"
      >
        <ProductCard
          class="h-full"
          :product="product"
          :size="productCardSizes['REGULAR']"
        /></div
    ></template>
  </div>
</template>

<script setup lang="ts">
import type { CmsElement } from "@/types/cms";
import ProductCard from "@/components/product/ProductCard.vue";
import ContentSlider from "@/components/molecules/ContentSlider";
import type { Schemas } from "#shopware";
import { useBreakpoints } from "@vueuse/core";
import { stringifiedMediaBreakpointsResolver } from "@/constants/mediaBreakpointsResolver";
import { productCardSizes } from "@/constants/productCard";

const breakpoints = useBreakpoints(stringifiedMediaBreakpointsResolver);
defineProps<{
  content: CmsElement<{
    products: string[];
    row: string;
    navigation: string;
    showSliderOnDesktop?: boolean;
    breakpoints: Record<
      string,
      { slidesPerView: number; spaceBetween: number }
    >;
  }>;
}>();
</script>
